import { useState, useEffect } from 'react';
import AnimatedScoreBar from './animatedScoreBar';
import '../assets/style/rank.css';

function Rank() {
    const [results, setResults] = useState([]);
    const [collected, setCollectedData] = useState([]);

    const [sketchyScore, setSketchyScore] = useState(0); // Example initial sketchy score
    const [dataScore, setDataScore] = useState(0); // Example initial data score
    const [lengthScore, setLengthScore] = useState(0); // Example initial length score
    const [totalScore, setTotalScore] = useState(0); // Example initial total score

    useEffect(() => {
        // Get the query parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const q = urlParams.get('q');
        const t = urlParams.get('t');
        // Check if q is a string and a valid URL
        if (typeof q === 'string' && q.match(/^https?:\/\/[^\s/$.?#].[^\s]*$/)) {
            // Make a GET request to 127.0.0.1:5001/rank?q=URL

            // Extract the domain from the URL
            const url = new URL(q);
            const domain = url.hostname;

            // Print the domain in a h4 tag
            const domainElement = document.getElementById('serviceName');
            domainElement.textContent = `${domain}`;
            
            document.getElementById("loadingSpinner").classList.remove("d-none");

            fetch(`https://api.watsonprivacy.com/rank?q=${encodeURIComponent(q)}&t=${encodeURIComponent(t)}`)
                .then(response => response.json())
                .then(result => {
                    // showing results in the page
                    document.getElementById("loadingSpinner").classList.add("d-none");
                    document.getElementById("lastModified").innerText = `Last modified: ${result.lastmodified}`;
                    document.getElementById("highlightsTitle").classList.remove("d-none");
                    document.getElementById("collectedDataTitle").classList.remove("d-none");
                    document.getElementById("dataList").classList.remove("d-none");
                    document.getElementById("scoreWrapper").classList.remove("d-none");
                    console.log(result);
                    setResults(result.analysis.analysis);
                    setCollectedData(result.analysis.collected.sort(function (a, b) {
                        return a.length - b.length;
                    }));
                    setSketchyScore(result.analysis.transparency);
                    setDataScore(result.analysis.data);
                    setLengthScore(result.analysis.control);
                    setTotalScore(result.analysis.total);

                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }

    }, []);

    return (
        <div id="rankContainer">
            <div className="text-center">
                <h2>Analysis results for <span id="serviceName" className="yellow"></span></h2>
                <h4 id="lastModified"></h4>
            </div>
            <div className="text-center d-none mt-5" id="loadingSpinner">
                <div className="spinner-border yellow" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
            <div className="p-5">
                <div className="row">
                    <div id="scoreWrapper" className="col-lg-6 col-md-12 col-sm-12 text-center d-none p-5">
                        <h3 className="mb-4">Service score:</h3>
                        <h3 className="mt-5"><span id="scoreText" style={{ color: getScoreColor(totalScore) }}>{Math.ceil(totalScore)}</span>/100</h3>
                        <div className="row mt-5 d-flex align-items-center">
                            <div className="col-sm-12 col-md-3 text-center">
                                <h4>Transparency</h4>
                            </div>
                            <div className="col">
                                <AnimatedScoreBar score={sketchyScore} color={getScoreColor(sketchyScore)} />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col-sm-12 col-md-3 text-center">
                                <h4>Data Collection</h4>
                            </div>
                            <div className="col">
                                <AnimatedScoreBar score={dataScore} color={getScoreColor(dataScore)} />
                            </div>
                        </div>
                        <div className="row  d-flex align-items-center">
                            <div className="col-sm-12 col-md-3 text-center">
                                <h4>User Control</h4>
                            </div>
                            <div className="col">
                                <AnimatedScoreBar score={lengthScore} color={getScoreColor(lengthScore)} />
                            </div>
                        </div>
                    </div>
                    <div className="col text-center">
                        <h3 id="collectedDataTitle" className="d-none pt-5">What data is collected:</h3>
                        <div className="mb-3 p-4">
                            <div className="d-none flex-container" id="dataList">
                                {collected.map((data, index) => (
                                    <p key={index} className="m-2 dataBubble">{data}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-5 ms-5 me-5">
                <div>
                    <div className="row">
                        <h3 id="highlightsTitle" className="d-none">Highlights:</h3>
                    </div>
                    <div className="row">
                        <div id="analysisContainer mb-3">
                            {results.map((result, index) => (
                                <div key={index} className="row mt-4 d-flex justify-content-center align-items-center">
                                    <div className="analysisResult col-lg-12 col-md-12 col-sm-12 col-9">
                                        <div className="dataLeak">
                                            <p className="mt-4">{result.sentence}</p>
                                        </div>
                                        <div className="explanation">
                                            <p className="mt-4">{result.explanation}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

function getScoreColor(score) {
    if (score < 33) {
        return '#6e0d25';
    } else if (score < 66) {
        return '#a75632';
    } else {
        return '#77966d';
    }
}

export default Rank; 
