import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function Features() {
  return (
    <div className="grid-section _130top">
      <div id="w-node-d3fccb1843dd-d9e35a76" className="mt-5">
        <h2>Our service offers various parameters to help you understand why a service might put your data at risk, namely:</h2>
        <h3>Sentences taken directly from the Privacy Policy, followed by a more understandable explanation of the potential risks they pose</h3>
        <h3>A list of all the data collected and automatically derived by the service, with or without direct user consent.</h3>
        <h3>Considerations regarding the general transparency of the policy (how clear and direct the service is with how it handles data).</h3>
        <h3>A deeper background analysis on the different levels of risk your data is at, depending on the potentially dangerous elements of the Privacy Policies and what they express.</h3>
        <h3>How much control over your data you, the user, have.</h3>
        <h2>And, eventually, a general score that encompasses all of the parameters mentioned above and provides you with an overall summary of our analysis.</h2>
      </div>
    </div>
  );
}

export default Features;


