import React, { useEffect, useRef } from 'react';
import '../assets/style/rank.css';

const AnimatedScoreBar = ({ score, color}) => {
    const scoreBarRef = useRef(null);

    useEffect(() => {
        const bar = scoreBarRef.current;
        bar.style.width = '0'; // Reset to 0 to trigger animation
        bar.style.transition = 'none'; // Disable transition to reset width
        // Trigger a reflow to apply the width reset
        (() => bar.offsetHeight)();
        // Set the final width with transition
        bar.style.width = `${score}%`;
        bar.style.backgroundColor = color;
        bar.style.transition = 'width 1s ease-in-out';
    }, [score]);

    return (
        <div className="progress-container">
            <div ref={scoreBarRef} className="progress-bar">
                {score}%
            </div>
        </div>
    );
};

export default AnimatedScoreBar;
