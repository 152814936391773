import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Find from './Find'


function App() {
  return (
    <div className="grid-section _130top">
      <div id="w-node-d3fccb1843dd-d9e35a76" className="hero-container">
        <div id="w-node-d3fccb1843e1-d9e35a76" className="hero-content mt-5 fade-in-move-up">
          <h1>Mind your own data.</h1>
          <p className="sub-text-hero">Watson enables you to control what data goes to who when you accept
            privacy policies.</p>
          <Link to="/find" className="button yellow w-inline-block">
            <div>Start today</div>
          </Link>
          <Routes>
            <Route path="/find" component={Find} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;


