import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import '../assets/style/extra.css';

function About() {
  return (
    <div className="grid-section _130top">
      <div id="w-node-d3fccb1843dd-d9e35a76" className="mt-5 description">
        <h2 style={{ fontWeight: 400 }}>In a world where data is the new currency, it is of the utmost importance to know how and when your personal information is shared.</h2>
        <div className="text-center">
          <h2 className="mt-5 pt-5">That is what we value here at Wats<span className="yellow">on</span>: user awareness</h2>
        </div>
        <h2 className="mt-5" style={{ fontWeight: 400 }}>Thanks to the power of Artificial Intelligence, you will never need to read - or worse, blindly sign - any privacy policies ever again: we will find them, analyze them, and report any potential privacy risks right back to you, thus allowing you to make a more informed decision when signing up for yet another service.</h2>
        <div className="text-center mt-5">
          <h2>Easy, right?</h2>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-auto">
            <Link to="/find" className="button yellow w-inline-block mt-5">
              <div>Start today</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;


