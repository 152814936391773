import { useState } from 'react';
import '../assets/style/find.css';
import Select from './select';

function isValidURL(string) {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
}

function Find() {
    const [showResults, setShowResults] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = async () => {
        try {
            // TODO sanitize
            let serviceURL = document.getElementById('serviceURL').value;
            if (!serviceURL || serviceURL === '') {
                console.error('No service URL provided');
                return;
            }
            if (!isValidURL(serviceURL)) {
                serviceURL = `https://${serviceURL}`;
            }
            document.getElementById("loadingSpinner").classList.remove("d-none");
            document.getElementById("searchButton").classList.add("d-none");
            const response = await fetch(`https://api.${window.location.hostname}/find?q=${serviceURL}`, {
                method: 'GET'
            });
            document.getElementById("loadingSpinner").classList.add("d-none");
            document.getElementById("searchButton").classList.remove("d-none");
            if (response.status === 200) {
                console.log(response.status)
                let data = await response.json();
                console.log(data)
                if (data.length > 0) {
                    window.location.href = "/rank?q=" + data;
                } else {
                    document.getElementById('serviceURL').placeholder = "No results";
                    setShowResults(false);
                }
            } else {
                document.getElementById('serviceURL').value = ""
                document.getElementById('serviceURL').placeholder = "No results";
                console.error('Search request failed');
            }
        } catch (error) {
            console.error('An error occurred during search:', error);
        }
    };

    const handleBackToSearch = () => {
        setShowResults(false);
    };

    return (
        /*<div id="finderContainer">
            <div className="text-center">
                <h2>Find policies</h2>
                <h4 className="mt-4">Send the service's URL you are interested in and we will find the available policies to analyze</h4>
            </div>
            <div className="mt-5 pt-3 d-flex justify-content-center">
                {showResults ? (
                    <div id="resultsWrapper" className="col-lg-3 col-md-5 col-sm-6 col-9 text-center">
                        <div className="text-center">
                            <h4>Results</h4>
                        </div>
                        <div id="finderResults" className="row d-flex mt-4 justify-content-center">
                            {searchResults.map((result, index) => (
                                <div className="row" key={index}>
                                    <a href={`/rank?q=${result}`} className="urlOption">{result.replace('https://', '')}</a>
                                </div>
                            ))}
                            <div className="row">
                                <a href="#" className="backToSearch" id="backToSearchButton" onClick={handleBackToSearch}>Back to search</a>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id="finderForm" className="col-lg-3 col-md-5 col-sm-6 col-9 text-center">
                        <div className="row">
                            <h4 className="text-center">Service URL</h4>
                        </div>
                        <div className="row">
                            <input type="text" className="form-control" id="serviceURL" placeholder="www.example.com" />
                        </div>
                        <div className="row mt-3">
                            <button className="btn button yellow w-inline-block" id="searchButton" onClick={handleSearch}>Search</button>
                        </div>
                    </div>
                )}
            </div>
            <div className="text-center d-none" id="loadingSpinner">
                <div className="spinner-border yellow" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div>*/
        
        <div id="finderContainer">
            <div className="text-center">
                <h2>Find policies</h2>
                <h4 className="mt-4">Send the service's URL you are interested in and we will find the available policies to analyze</h4>
            </div>
            <Select />
        </div>
    );
}

export default Find;
